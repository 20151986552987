<template>
  <div class="log-manage">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" content="事件模版" />
    <!-- 搜索框 -->
    <ml-tip class="auto-send" :showHeader="false" style="border: 0">
      <ml-form
        style="display: flex"
        inline
        labelWidth="100px"
        :model="searchData"
        ref="searchDataRef"
      >
        <!-- 操作类型 -->
        <ml-input
          prop="titleLike"
          placeholder="请输入短信标题"
          style="margin-bottom: 0; margin-right: 40px"
          label="消息标题:"
          v-model="searchData.titleLike"
        />

        <!-- 搜索按钮 -->
        <ml-button
          :style="{ 'margin-bottom': 0 }"
          submitText="搜索"
          submitIcon="el-icon-search"
          cancelText="重置"
          cancelIcon="el-icon-refresh"
          cancelType="danger"
          @click-cancel="resetSearchData"
          @click-submit="searchFn"
        />
        <!-- 搜索按钮 -->
        <ml-button
          size="medium"
          :style="{ 'margin-bottom': 0 }"
          submitText="添加模版"
          submitIcon="el-icon-plus"
          :showCancel="false"
          @click-submit="clickSmes"
        >
        </ml-button>
      </ml-form>
      <el-switch
        active-text="自动发送"
        v-model="isAutoSend"
        @change="setAutoSend"
        active-color="#13ce66"
      />
    </ml-tip>
    <!-- 数据表格 -->
    <ml-tip :showHeader="false" class="default-tip" style="margin-bottom: 0">
      <!-- 表格 -->
      <el-table :data="tabberData.data" style="width: 100%" :height="tabberHeight(319)">
        <el-table-column align="center" type="index" width="80">
          <template #default="scope">
            {{ (tabberData.page - 1) * tabberData.size + (scope.$index + 1) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="模版名称" prop="templateName" min-width="10%" />
        <el-table-column align="center" label="消息标题" prop="title" min-width="10%" />
        <el-table-column
          show-overflow-tooltip
          align="center"
          label="区域"
          prop="area"
          min-width="40%"
        >
          <template #default="scope">
            {{ scope.row.province }}{{ scope.row.city }}{{ scope.row.area }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="触发条件"
          show-overflow-tooltip
          prop="condition"
          min-width="40%"
        >
          <template #default="scope">
            {{ setConditionVos(scope.row.conditionVos || []).join() }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="发送对象" prop="userStatus" min-width="10%">
          <template #default="scope">
            {{ scope.row.userStatus === '0' ? '全部' : '指定用户' }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime" min-width="18%" />
        <el-table-column align="center" label="操作" priop="templateId" min-width="40%">
          <template #default="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              type="primary"
              @click="clickEdit(scope.$index, scope.row)"
            >
              修改
            </el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="clickDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <ml-pagination
        :total="tabberData.total"
        :page="tabberData.page"
        v-model="tabberData.page"
        :size="tabberData.size"
        @current-change="currentChange"
        @size-change="sizeChange"
      />
    </ml-tip>
    <!-- 删除设备提示框 -->
    <ml-dialog
      ref="deleteSmsTemplatesDialogRef"
      content="确定删除该模版吗？"
      @click-submit="deleteSmsTemplatesDialogSubmit"
    />
    <!-- 发送消息提示框 -->
    <ml-dialog
      width="600px"
      ref="smesDialogRef"
      :title="smesDialogData.title"
      defaultClose
      showClose
      :showCloseBtn="false"
      @click-submit="submitSmesDialog"
    >
      <template #body>
        <ml-form
          labelWidth="120px"
          style="width: 100%"
          :model="submitSmesDialogForm"
          :rules="submitSmesDialogFormRules"
          ref="submitSmesDialogFormRef"
        >
          <ml-input
            label="模版名称:"
            prop="templateName"
            placeholder="请输入模版名称"
            v-model.trim="submitSmesDialogForm.templateName"
          />
          <!-- 多级下拉框-管理区域 -->
          <ml-cascader
            prop="manageArea"
            label="触发条件:"
            :options="areaData"
            :props="{ multiple: false, label: 'name', value: 'name', checkStrictly: true }"
            placeholder="请选择触发条件"
            v-model="submitSmesDialogForm.manageArea"
            @change="manageAreaChange"
          />
          <!-- 多级下拉框-管理区域 -->
          <ml-cascader
            prop="conditionVos"
            label="天气类型:"
            :options="weatherTypeData"
            clearable
            collapseTags
            :props="{ multiple: true, label: 'name', value: 'name', children: 'conditions' }"
            placeholder="请选择天气类型"
            v-model="submitSmesDialogForm.conditionVos"
            v-model:node="submitSmesDialogForm.conditionVosNode"
          />

          <ml-input
            prop="title"
            label="推送标题:"
            placeholder="请输入推送标题"
            v-model.trim="submitSmesDialogForm.title"
          />

          <ml-input
            label="消息内容:"
            prop="content"
            type="textarea"
            :rows="5"
            placeholder="请输入消息内容"
            v-model.trim="submitSmesDialogForm.content"
          />

          <el-form-item label="推送对象" prop="userStatus">
            <el-radio-group v-model="submitSmesDialogForm.userStatus">
              <el-radio label="0">全部</el-radio>
              <el-radio label="1">指定人</el-radio>
              <el-button
                v-if="submitSmesDialogForm.userStatus === '1'"
                size="mini"
                type="primary"
                @click="clickOpenSmesPeopleDialog"
              >
                添加人员
              </el-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="submitSmesDialogForm.userVos.length > 0">
            <el-tag
              style="margin: 5px 10px"
              type="default"
              v-for="item in submitSmesDialogForm.userVos"
              :key="item"
              >{{ item.userName }}</el-tag
            >
          </el-form-item>
        </ml-form>
      </template>
    </ml-dialog>
    <!-- 添加指派人提示框 -->
    <ml-dialog
      width="1200px"
      ref="smesPeopleDialogRef"
      title="指定人"
      @clickSubmit="smesPeopleDialogSubmit"
      @clickClose="smesPeopleDialogClose"
    >
      <template #body>
        <div>
          <div>
            <ml-form
              inline
              labelWidth="80px"
              :model="smesPeopleDialogForm"
              ref="smesPeopleDialogFormRef"
            >
              <!-- 帐号 -->
              <ml-input
                prop="userCodeLike"
                placeholder="请输入账号"
                label="帐号:"
                v-model="smesPeopleDialogForm.userCodeLike"
              />
              <!-- 用户 -->
              <ml-input
                prop="userNameLike"
                placeholder="请输入用户名"
                label="用户名:"
                v-model="smesPeopleDialogForm.userNameLike"
              />
              <ml-select
                class="assignedPeople-select"
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                style="margin-right: 40px"
                v-model="smesPeopleDialogForm.companyId"
              />
              <!-- 搜索按钮 -->
              <ml-button
                submitText="搜索"
                submitIcon="el-icon-search"
                cancelText="重置"
                cancelIcon="el-icon-refresh"
                cancelType="danger"
                @click-cancel="resetSearchDataUser"
                @click-submit="searchFnUser"
              />
            </ml-form>
          </div>
          <div class="assignedPeople-table-box">
            <el-table
              :data="tabberDataUser.data"
              style="width: 100%"
              :height="200"
              @selection-change="selectionChangeHandle"
              row-key="userId"
              ref="smesPeopleDialogTableRef"
            >
              <el-table-column type="selection" :reserve-selection="true" width="55" />
              <el-table-column align="center" label="账号" prop="userCode" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="用户名"
                prop="userName"
                min-width="15%"
              />
              <el-table-column align="center" label="阿米巴" prop="companyName" min-width="30%" />
              <el-table-column
                show-overflow-tooltip
                align="center"
                label="职位"
                prop="job"
                min-width="20%"
              />
            </el-table>
            <!-- 分页 -->
            <ml-pagination
              :total="tabberDataUser.total"
              :page="tabberDataUser.page"
              v-model="tabberDataUser.page"
              :size="tabberDataUser.size"
              @current-change="currentChangeUser"
              @size-change="sizeChangeUser"
            />
          </div>
        </div>
      </template>
    </ml-dialog>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch } from 'vue'
import { tabberHeight, searchParams, getTabberData, getWeatherType, replacePerCent } from '@/utils'
import { useStore } from 'vuex'

import { uniqueObjectsByKey } from '@/utils/index'

export default {
  name: 'EventTemplates',
  setup() {
    const { commit, dispatch } = useStore()
    /* 开始 搜索 **********************************/
    // 搜索条件
    const searchDataRef = ref()
    const searchData = reactive({
      titleLike: '',
      date: []
    })
    // 重置搜索内容
    const resetSearchData = () => {
      searchDataRef.value.CustomFormRef.resetFields()
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    // 搜索
    const searchFn = () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    }
    /* 结束 搜索 **********************************/

    /* 开始 数据列表 **********************************/
    // 表格配置
    const tabberData = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10
    })

    // 获取列表
    const getLogTabberData = async (
      params = { pageIndex: tabberData.page, pageSize: tabberData.size }
    ) => {
      const searchDataParams = searchParams(searchData)
      searchDataParams.titleLike = replacePerCent(searchData.titleLike)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchgEtEventTemplates',
        params,
        searchDataParams
      )
      tabberData.data = data || []
      tabberData.total = total || 0
    }
    //分页事件
    watch([() => tabberData.page, () => tabberData.size], () => {
      tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
    })
    const currentChange = page => {
      tabberData.page = page
    }
    const sizeChange = size => {
      tabberData.size = size
    }
    /* 结束 数据列表 **********************************/

    // 删除
    const deleteSmsTemplatesDialogRef = ref()
    const deleteSmsTemplatesDialogData = reactive({
      templateId: ''
    })
    const clickDelete = (index, row) => {
      deleteSmsTemplatesDialogData.templateId = row.templateId
      deleteSmsTemplatesDialogRef.value.showDialog = true
    }
    const deleteSmsTemplatesDialogSubmit = () => {
      dispatch('fetchDeleteEventTemplate', deleteSmsTemplatesDialogData.templateId).then(
        async data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
            deleteSmsTemplatesDialogRef.value.showDialog = false
          }
        }
      )
    }

    // 发送消息
    // 单位
    const unitOptions = ref([])
    // 管理区域配置
    const areaData = ref([])
    // 天气类型
    const weatherTypeData = ref(getWeatherType())

    const smesDialogRef = ref()
    const smesDialogData = reactive({
      title: ''
    })
    // 处理天气类型
    const setConditionVos = (conditionVos = []) => {
      const nConditionVos = []
      conditionVos.map(item => {
        const nameArr = item.conditions.map(v => v.name)
        nConditionVos.push(`${item.name}(${nameArr.join(',')})`)
      })
      return nConditionVos
    }
    // 打开发送
    const clickSmes = () => {
      smesDialogData.title = '添加模版'
      submitSmesDialogForm.templateName = ''
      submitSmesDialogForm.title = ''
      submitSmesDialogForm.content = ''
      submitSmesDialogForm.userStatus = '0'
      submitSmesDialogForm.manageArea = []
      submitSmesDialogForm.userVos = []
      submitSmesDialogForm.conditionVos = []
      submitSmesDialogForm.conditionVosNode = []
      submitSmesDialogForm.companyId = ''
      smesDialogRef.value.showDialog = true
    }
    // 打开发送
    const clickEdit = async (index, row) => {
      smesDialogData.title = '修改模版'
      submitSmesDialogForm.templateId = row.templateId
      // 获取区域
      const data = await dispatch('fetchGetEventTemplate', row.templateId)
      if (data && data.code === 200 && data.data) {
        const {
          templateName = '',
          title = '',
          content = '',
          userStatus = '0',
          userVos = [],
          conditionVos = [],
          province = '',
          city = '',
          area = '',
          companyId = ''
        } = data.data
        submitSmesDialogForm.templateName = templateName
        submitSmesDialogForm.title = title
        submitSmesDialogForm.content = content
        submitSmesDialogForm.userStatus = userStatus
        submitSmesDialogForm.manageArea = [province, city, area].filter(d => d)
        submitSmesDialogForm.userVos = userVos || []
        if (conditionVos && conditionVos.length > 0) {
          const nConditionVos = []
          submitSmesDialogForm.conditionVos = conditionVos.map(item => {
            item.conditions.map(v => {
              nConditionVos.push([item.name, v.name])
            })
          })
          submitSmesDialogForm.conditionVos = nConditionVos
          submitSmesDialogForm.conditionVosNode = conditionVos
        }
        submitSmesDialogForm.companyId = companyId
      }
      smesDialogRef.value.showDialog = true
    }
    // 确认发送
    const submitSmesDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validate()
        .then(() => {
          let paramsName = 'fetchAddEventTemplate'
          const nParams = searchParams(
            submitSmesDialogForm,
            ['userVos', 'manageArea', 'conditionVos', 'conditionVosNode', 'templateId'],
            true
          )
          if (smesDialogData.title === '修改模版') {
            paramsName = 'fetchUpdateEventTemplate'
            nParams.templateId = submitSmesDialogForm.templateId
          }
          if (submitSmesDialogForm.userStatus === '1') {
            nParams.userVos = submitSmesDialogForm.userVos
          }
          if (submitSmesDialogForm.manageArea.length > 0) {
            const [province, city, area] = submitSmesDialogForm.manageArea
            nParams.province = province
            nParams.city = city
            nParams.area = area
          }
          if (submitSmesDialogForm.conditionVos.length > 0) {
            nParams.conditionVos = submitSmesDialogForm.conditionVosNode
          }
          dispatch(paramsName, nParams).then(async data => {
            if (data && data.code === 200) {
              commit('setError', {
                status: true,
                title: data.message,
                message: '正在获取新的数据...',
                type: 'success'
              })
              tabberData.page === 1 ? getLogTabberData() : (tabberData.page = 1)
              smesDialogRef.value.showDialog = false
            }
          })
        })
        .catch(() => {})
    }
    // 发送对话框表单
    // 选择指派人列表
    const selectData = ref([])
    const submitSmesDialogFormRef = ref()
    const submitSmesDialogForm = reactive({
      templateName: '',
      manageArea: [],
      conditionVos: [],
      conditionVosNode: [],
      title: '',
      content: '',
      userStatus: '0',
      userVos: []
    })
    const manageAreaChange = () => {
      submitSmesDialogForm.userVos = []
    }
    // 表单校验
    const isUserStatusOne = () => {
      const { userStatus, userVos } = submitSmesDialogForm
      if (userStatus === '1' && userVos.length <= 0) {
        return false
      } else {
        return true
      }
    }
    const submitSmesDialogFormRules = {
      manageArea: [{ required: true, message: '请选择区域 ', trigger: 'blur' }],
      title: [{ required: true, message: '请输入推送标题 ', trigger: 'blur' }],
      content: [{ required: true, message: '请输入推送内容 ', trigger: 'blur' }],
      userStatus: [{ validator: isUserStatusOne, message: '请选择接收人', trigger: 'blur' }]
    }

    // 指定人 *******************************************************************
    // 对话框
    const smesPeopleDialogRef = ref()
    const smesPeopleDialogTableRef = ref()
    // 打开对话框
    const clickOpenSmesPeopleDialog = () => {
      submitSmesDialogFormRef.value.CustomFormRef.validateField(['manageArea'])
      if (submitSmesDialogForm.manageArea.length > 0) {
        const [province, city, area] = submitSmesDialogForm.manageArea
        smesPeopleDialogForm.province = province
        smesPeopleDialogForm.city = city
        smesPeopleDialogForm.area = area
        selectData.value = submitSmesDialogForm.userVos
        getAssignedPeopleData()
        smesPeopleDialogRef.value.showDialog = true
      }
    }
    // 确定对话框
    const smesPeopleDialogSubmit = () => {
      let userList = selectData.value
      userList = uniqueObjectsByKey(userList, 'userId')
      submitSmesDialogForm.userVos = userList
      smesPeopleDialogRef.value.showDialog = false
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }
    // 关闭对话框
    const smesPeopleDialogClose = () => {
      selectData.value = []
      smesPeopleDialogTableRef.value.clearSelection()
    }

    // 表单
    const smesPeopleDialogFormRef = ref()
    const smesPeopleDialogForm = reactive({
      userCodeLike: '',
      userNameLike: '',
      companyId: '',
      province: '',
      city: '',
      area: ''
    })

    // 表格配置
    const tabberDataUser = reactive({
      data: [],
      total: 0,
      page: 1,
      size: 10,
      isSubmie: false
    })

    // 获取账户列表
    const getAssignedPeopleData = async (
      params = { pageIndex: tabberDataUser.page, pageSize: tabberDataUser.size }
    ) => {
      const searchDataParams = searchParams(smesPeopleDialogForm)
      const { data, total } = await getTabberData(
        dispatch,
        'fetchGetAppUsers',
        params,
        searchDataParams
      )
      tabberDataUser.data = data || []
      tabberDataUser.total = total || 0
      if (selectData.value.length > 0) {
        selectData.value.map(item => {
          smesPeopleDialogTableRef.value.toggleRowSelection(item, true)
        })
      }
    }

    //分页事件
    watch([() => tabberDataUser.page, () => tabberDataUser.size], () => {
      getAssignedPeopleData()
    })
    const currentChangeUser = page => {
      tabberDataUser.page = page
    }
    const sizeChangeUser = size => {
      tabberDataUser.size = size
    }

    // 重置搜索内容
    const resetSearchDataUser = () => {
      smesPeopleDialogFormRef.value.CustomFormRef.resetFields()
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }
    // 搜索
    const searchFnUser = () => {
      tabberDataUser.page === 1 ? getAssignedPeopleData() : (tabberDataUser.page = 1)
    }

    // 选中的添加到列表
    const getSelection = selection =>
      selection.map(item => {
        return {
          userId: item.userId,
          userName: item.userName,
          telephone: item.telephone
        }
      })

    const selectionChangeHandle = selection => {
      console.log(44343, selection)
      selectData.value = getSelection(selection)
    }

    // 自动发送
    const isAutoSend = ref(false)
    const setAutoSend = val => {
      const status = val ? 'Y' : 'N'
      dispatch('fetchUpdateEventAlarmStatus', status).then(data => {
        if (data && data.code === 200) {
          commit('setError', {
            status: true,
            title: data.message,
            message: '正在获取新的数据...',
            type: 'success'
          })
        }
      })
    }

    onMounted(async () => {
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      // 获取区域
      const areaResult = await getTabberData(dispatch, 'fetchGetCityAreaTrees', {})
      areaData.value = areaResult.data || []
      // 查询预警事件是否自动发送
      const eventAlarmStatusData = await getTabberData(dispatch, 'fetchGetEventAlarmStatus')
      isAutoSend.value = eventAlarmStatusData && eventAlarmStatusData.data === 'Y' ? true : false

      getLogTabberData()
    })
    return {
      searchDataRef,
      searchData,
      resetSearchData,
      searchFn,
      tabberData,
      currentChange,
      sizeChange,
      tabberHeight,
      deleteSmsTemplatesDialogRef,
      deleteSmsTemplatesDialogSubmit,
      clickDelete,
      areaData,
      weatherTypeData,
      clickEdit,
      clickSmes,
      smesDialogRef,
      smesDialogData,
      submitSmesDialog,
      submitSmesDialogForm,
      submitSmesDialogFormRef,
      submitSmesDialogFormRules,
      unitOptions,
      smesPeopleDialogRef,
      smesPeopleDialogSubmit,
      smesPeopleDialogClose,
      clickOpenSmesPeopleDialog,
      smesPeopleDialogForm,
      smesPeopleDialogFormRef,
      currentChangeUser,
      tabberDataUser,
      sizeChangeUser,
      resetSearchDataUser,
      searchFnUser,
      smesPeopleDialogTableRef,
      setConditionVos,
      manageAreaChange,
      isAutoSend,
      setAutoSend,
      selectionChangeHandle
    }
  }
}
</script>

<style lang="scss">
.log-manage {
  @extend %params-global;
  .auto-send {
    .el-card__body {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
